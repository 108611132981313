const initialState = {
    tag: '',
};

const UPDATE_TAG = 'UPDATE_TAG';

export const updateTag = tag => ({
  type: UPDATE_TAG, tag
});

export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_TAG:
        return { ...state, tag: action.tag };
      default:
        return state;
    }
  };