import React from "react";
import { Provider } from "react-redux";
import { createStore } from 'redux';
import rootReducer from './src/state';

const store = createStore(rootReducer);

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {

  if(location.state === null){
    return true;
  }

  return !location.state.keepScroll
  
}